<template>
    <div class="flex flex-col justify-between items-center w-full h-auto border-gray-100 drop-shadow-md">

        <div
            class="group/menu links w-full font-[Prata] items-center justify-center flex flex-wrap bg-evellePrimary py-2 md:py-1 rounded-sm">

            <div class="peer/menu overflow-hidden transition-[grid-template-rows] duration-500 w-full px-4 grid grid-rows-1 items-center flex-wrap md:flex-col md:grid-rows-0 "
                :class="[isNavigationOpen ? 'md:grid-rows-1' : '']" id="navbar-default">
                <div ref="navBar"
                    class=" h-full flex justify-between items-center flex-wrap md:items-start md:flex-nowrap md:gap-2 md:flex-col md:flex md:py-4">
                    <NuxtLink v-if="links" @click="isNavigationOpen = false"
                        :class="{ 'router-link-active': (link.path == currentRoute.path || (currentRoute.path == '/hem' && link.path == '')) }"
                        class="flex items-center mx-2 text-white sm:text-sm sm:min-h-[48px] md:hover:ml-4 hover:text-hover px-2 py-1 cursor-pointer rounded-md transition-all"
                        :to="{ path: link.path == '' ? '/' : link.path }" v-for="link in links.filter(x => x.path != '/' && x.name != 'slug')">
                        {{ link.name }}
                    </NuxtLink>
                </div>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import type { Menu } from '../../types/backendtypes'
import { useRouter } from 'nuxt/app';
import type { _StoreWithState } from 'pinia';
export default defineNuxtComponent({
    name: 'navbar',
    watch: {
        isNavigationOpen(newStatus, oldStatus) {

            if (newStatus) {
                document.addEventListener('click', this.closeMenuOutsideClick, true);
            } else {
                document.removeEventListener('click', this.closeMenuOutsideClick, true);
            }
        }
    },
    methods: {
        closeMenuOutsideClick(event: { target: any; }) {
            const navBar = this.$refs.navBar as HTMLElement;
            
            if (navBar.contains(event.target) && !event.target.classList.contains('burgerButton')) {
                document.removeEventListener('click', this.closeMenuOutsideClick, true);

                // This timeout is necessary for the functionality 
                setTimeout(() => {
                    this.isNavigationOpen = false;
                }, 1)
            }
        }
    },


    async setup() {
        const routes = useRouter();

        const {data, error} = await useFetch<Menu>('/api/menus', {
        headers: useRequestHeaders()
      });        
        if(error.value){
            console.log("NAVBAR ERROR: ",error.value)
            throw createError({statusCode: 500,statusMessage: "NavBAR Menus failed",message: "Menus is undefined",fatal: false,})
        }
        if(data.value === undefined || data.value == null || data == undefined ){
            throw createError({statusCode: 500, message: "Menus is undefined",fatal: false})
        }else{

        const links = data.value.menus ? data.value.menus : [];
        
        const hiddenRoutes = ['/login2'];

        links.forEach((link, index) => {
            if (hiddenRoutes.includes(link.path)) {
                delete links[index]
            }
        })

        // const { currentRoute } = storeToRefs(routes);
        const { currentRoute } = routes;
        const isNavigationOpen:Ref<boolean> = useState<boolean>('isNavigationOpen')

        return { links, currentRoute, isNavigationOpen, hiddenRoutes }
    }

    }

})
</script>

<style lang="scss">.router-link-active {
    @apply text-[#e3cd9a];
}</style>